import {React, useState, useEffect, useRef} from 'react';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/App.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import NavMenu from '../components/NavMenu';
import Graph from '../components/Graph';
import DigitalClock from '../components/DigitalClock';
import InfoCard from '../components/InfoCard';
import PerformanceCard from '../components/PerformanceCard';
import Map from '../components/Map';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';

function DashboardHome() {

  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  useEffect(() => {
    const savedSessionData = Cookies.get('sessionData');
    if (savedSessionData) {
      const data = JSON.parse(savedSessionData);
      if (data['time']) {
        setIntervalValue(data['time']);
      }
      if (data['isViewAllowed'] === 1) {
        navigateRef.current('/');
      }
    } else {
      navigateRef.current('/login');
    }
  }, []);

  const callbacksRef = useRef(() => callbacks());
  useEffect(() => {
    callbacksRef.current();
  }, []);


  const [interval, setIntervalValue] = useState([300000]);    // to refresh and reload (initial 5 min)
  useEffect(() => {
    const intervalId = setInterval(async () => {
        callbacksRef.current();
    }, interval);
    return () => {
        clearInterval(intervalId);                           // Clear the interval when the component unmounts
    };
  }, [interval]);   


  let currYear = new Date().getFullYear();
  let prevYear = currYear - 1;
  let nextYear = currYear + 1;

  let currDate = new Date(); // Get current date
  let nextYearFirstDay = new Date(currDate.getFullYear() + 1, 0, 1); // Jan 1st of next year
  let diffInMilliseconds = nextYearFirstDay - currDate;
  let remainingDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const [stuEnrollCountCur, setStuEnrollCountCur] = useState(['--']);
  const [stuApplyCountCur, setStuApplyCountCur] = useState(['--']);
  const [stuEnrollCountPrev, setStuEnrollCountPrev] = useState(['--']);
  const [stuApplyCountPrev, setStuApplyCountPrev] = useState(['--']);
  const [recruitersCount, setRecruitersCount] = useState(['--']);
  const [agentsCount, setAgentsCount] = useState(['--']);

  const [enrollVsMonth, setEnrolVsMonth] = useState([]);
  const [applyVsMonth, setApplyVsMonth] = useState([]);
  const [enrollVsMonthPrev, setEnrolVsMonthPrev] = useState([]);
  const [applyVsMonthPrev, setApplyVsMonthPrev] = useState([]);

  const [topEnroll, setTopEnrol] = useState([]);  
  const [topApplication, setTopApplication] = useState([]); 
  const [enrollCourse, setEnrollCourse] = useState([]); 

  const [studentAgeGroups, setStudentAgeGroups] = useState([]);
  const [studentCounts, setStudentCounts] = useState([]);
  const [studentCountries, setStudentCountries] = useState([]);
  const [studentGenders, setStudentGenders] = useState([]);

  // const [applyVsCountry, setApplyVsCountry] = useState([]);
  // const [enrollCountry, setEnrollCountry] = useState([]);
  // const [applyVsCountryPrev, setApplyVsCountryPrev] = useState([]);  

  async function callbacks(){
     getEnrollData(prevYear,"","");
     getEnrollData(currYear,"","");
     getApplyData(prevYear,"","");
     getApplyData(currYear,"","");
     getAgentsData(currYear,'13');
     getTopEnrollment();
     getTopApplication();
     getEnrollCourse(currYear, "");
     getEnrolledStudentsByAge();
     getEnrolledStudentsByGender();
     getEnrolledStudentsByCountries();
     // getEnrollCountryData(prevYear,"","");
  }

  async function getTopEnrollment(){
    const url = process.env.REACT_APP_API + `/getNewEnroled`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {}
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      if (data){
        setTopEnrol(data)
      }
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }
  
  async function getTopApplication(){
    const url = process.env.REACT_APP_API + `/getNewApplication`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {}
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      if (data){
        setTopApplication(data)
      }
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrollData(selectedYear, selectedRegion, selectedCourseType) {
    const url = process.env.REACT_APP_API + `/getNewPerformance`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {
        year: selectedYear,
        type: 'enrollment',
        recTeam: selectedRegion,
        courseTypeId: selectedCourseType
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleEnrolVsMonth(data, selectedYear);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getApplyData(selectedYear, selectedRegion, selectedCourseType) {
    const url = process.env.REACT_APP_API + `/getNewPerformance`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {
        year: selectedYear,
        type: 'application',
        recTeam: selectedRegion,
        courseTypeId: selectedCourseType
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleApplyVsMonth(data, selectedYear);
      // handleApplyVsCountry(data, selectedYear);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }
  
  async function getAgentsData(selectedYear, status) {
    const url = process.env.REACT_APP_API + `/getAgentsByStatus`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {
        year: selectedYear,
        status: status
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      if (data){
        setAgentsCount(data.length);
      }
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrollCourse(selectedYear, selectedCourseType) {
    const url = process.env.REACT_APP_API + `/getCourseEnrollmentList`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {
        year: selectedYear,
        courseTypeId: selectedCourseType
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleCourseEnroll(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrolledStudentsByAge() {
    const url = process.env.REACT_APP_API + `/getEnrolledStudents`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {}
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      // handleStudentGenders(data);
      handleStudentAgeGroups(data);
      // handleStudentCountries(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrolledStudentsByGender() {
    const url = process.env.REACT_APP_API + `/getEnrolledStudentsByGender`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {}
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleStudentGenders(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrolledStudentsByCountries() {
    const url = process.env.REACT_APP_API + `/getEnrolledStudentsByCountries`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {}
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleStudentCountries(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  // async function getEnrollCountryData(selectedYear, selectedRegion, selectedCourseType) {
  //   const url = process.env.REACT_APP_API + `/getNewPerformance`;
  //   const configs = {
  //     headers:{
  //       'Authorization': 'Bearer '+localStorage.getItem("token"),
  //       'Content-Type': 'application/json',   
  //     },
  //     params: {
  //       year: selectedYear,
  //       type: 'enrollment',
  //       recTeam: selectedRegion,
  //       courseTypeId: selectedCourseType
  //     }
  //   };
  //   try {
  //     const response = await axios.get(url, configs);
  //     const data = response.data.key;
  //     // handleApplyVsMonth(data, selectedYear);
  //     // handleApplyVsCountry(data, selectedYear, configs.params.type);
  //   } catch (error) {
  //     if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
  //       Cookies.remove('sessionData');
  //       localStorage.removeItem('token')
  //       navigate('/login')
  //       return;
  //     }
  //     console.error("Error fetching data:", error);
  //   }
  // }


  function handleEnrolVsMonth(data, year) {
    let count = 0;
    let stuCount = 0;
    const tempData = months.reduce((acc, month) => {
      if (data){
        const monthCount = data.reduce((total, element) => total + element[month.toLowerCase() + '_count'], 0);
        acc.push({ x: count, y: monthCount, label: month });
        count++;
        stuCount += monthCount;
        return acc;
      }
      else{
        return null;
      }
    }, []);
    if (year === currYear && data){
      setRecruitersCount(data.length);
      setEnrolVsMonth(tempData);
      setStuEnrollCountCur(stuCount);
    }
    else if (year === prevYear && data){
      setEnrolVsMonthPrev(tempData);
      setStuEnrollCountPrev(stuCount);
    }
  }

  function handleApplyVsMonth(data, year) {
    let count = 0;
    let stuCount = 0;
    const tempData = months.reduce((acc, month) => {
      if (data){
        const monthCount = data.reduce((total, element) => total + element[month.toLowerCase() + '_count'], 0);
        acc.push({ x: count, y: monthCount, label: month });
        count++;
        stuCount += monthCount;
        return acc;
      }
      else{
        return null;
      }
    }, []);
    if (year === currYear && data){
      setApplyVsMonth(tempData);
      setStuApplyCountCur(stuCount);
    }
    else if (year === prevYear && data){
      setApplyVsMonthPrev(tempData);
      setStuApplyCountPrev(stuCount);
    } 
  }

  function handleCourseEnroll(data){
    const tempData = data.map(course => ({
      label: course.course_code,
      y: course.total  
    }));
    setEnrollCourse(tempData);
  }

  function handleStudentGenders(data){
    const genderCounts = data.reduce((acc, student) => {
      if (student.gender === 'Male') {
        acc.male += 1;
      } else if (student.gender === 'Female') {
        acc.female += 1;
      }
      return acc;
    }, { male: 0, female: 0 });
  
    const tempData = [
      {
        label: 'Male',
        y: genderCounts.male
      },
      {
        label: 'Female',
        y: genderCounts.female
      }
    ];
  
    setStudentGenders(tempData);
  }

  function handleStudentAgeGroups(data){
    const ageGroupCounts = data.reduce((acc, student) => {
      if (student.age >= 5 && student.age <= 19) {
        acc.ageGroup1 += 1;
      } else if (student.age > 19 && student.age <= 25) {
        acc.ageGroup2 += 1;
      } else if (student.age > 25 && student.age <= 30) {
        acc.ageGroup3 += 1;
      } else if (student.age > 30) {
        acc.ageGroup4 += 1;
      }
      return acc;
    }, { ageGroup1: 0, ageGroup2: 0, ageGroup3: 0, ageGroup4: 0 });

    const tempData = [
      {
        label: '< 19',
        y: ageGroupCounts.ageGroup1
      },
      {
        label: '20-25',
        y: ageGroupCounts.ageGroup2
      },
      {
        label: '26-30',
        y: ageGroupCounts.ageGroup3
      },
      {
        label: '30+',
        y: ageGroupCounts.ageGroup4
      }
    ];
    
    setStudentAgeGroups(tempData);
  }

  function handleStudentCountries(data){
    const countryStudentCount = data.reduce((acc, student) => {
      const { country_name } = student;
      const country = acc.find(c => c.country_name === country_name);
      if (!country) {
        acc.push({
          country_name: country_name,
          student_count: 1,
          coordinates: [student.longitude, student.latitude]
        });
      } else {
        country.student_count += 1;
      }    
      return acc;
    }, []);
    setStudentCounts(countryStudentCount);


    const countries = data.reduce((acc, studentCountry) => {
      const { country_name } = studentCountry;
      const country = acc.find(c => c.country_name === country_name);
      if (!country) {
        acc.push({
          country_name: country_name,
          coordinates: [studentCountry.longitude, studentCountry.latitude]
        });
      }   
      return acc;
    }, []);
    setStudentCountries(countries);
  }

  
  // function handleApplyVsCountry(data, year, type) {
  //   let tempData = [];
  //   let chinaCount = 0;
  //   let singaporeCount = 0;
  //   let othersCount = 0;
  //   if(data){
  //     data.forEach((element, index) => {
  //       if (element['country_name'] === "China"){
  //         months.forEach((month, index1) => {
  //           chinaCount += (element[month.toLowerCase() + '_count']);
  //         })
  //       }
  //       else if(element['country_name'] === "Singapore"){
  //         months.forEach((month, index1) => {
  //           singaporeCount += (element[month.toLowerCase() + '_count']);
  //         })
  //       }
  //       else {
  //         months.forEach((month, index1) => {
  //           othersCount += (element[month.toLowerCase() + '_count']);
  //         })
  //       }
  //     });
  //     tempData.push({ y: chinaCount, label: "China" });
  //     tempData.push({ y: singaporeCount, label: "Singapore" });
  //     tempData.push({ y: othersCount, label: "Others" });
  //     if(type === 'enrollment') {
  //       setEnrollCountry(tempData)
  //     } else {
  //       if (year === currYear){
  //         setApplyVsCountry(tempData);
  //       }
  //       else{
  //         setApplyVsCountryPrev(tempData);
  //       }
  //     }
  //   }
  // }


  const [isSec1Visible, setSec1Visible] = useState(true); 
  const [isSec2Visible, setSec2Visible] = useState(false);
  const [isSec3Visible, setSec3Visible] = useState(false);
  const [slideVisible, setSlideVisible] = useState();
  const [slideExit, setSlideExit] = useState();

  function changeSection(direction = null){
    if(direction == 'l'){
      setSlideVisible('slide-visible-l');
      setSlideExit('slide-exit-l');

      if(isSec1Visible){
        setSec1Visible(false); setSec2Visible(false); setSec3Visible(true);
      }
      else if(isSec2Visible){ 
        setSec1Visible(true); setSec2Visible(false); setSec3Visible(false);
      }
      else {
        setSec1Visible(false); setSec2Visible(true); setSec3Visible(false);
      }
    }
    else{
      setSlideVisible('slide-visible-r');
      setSlideExit('slide-exit-r');

      if(isSec1Visible){
        setSec1Visible(false); setSec2Visible(true); setSec3Visible(false);
      }
      else if(isSec2Visible){ 
        setSec1Visible(false); setSec2Visible(false); setSec3Visible(true);
      }
      else {
        setSec1Visible(true); setSec2Visible(false); setSec3Visible(false);
      }
    }
  }

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === 'ArrowRight') {
        event.preventDefault();
        changeSection('r');
      }
      if (event.key === 'ArrowLeft') {
        event.preventDefault();
        changeSection('l');
      }
    }

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [changeSection]);


  return (
    <div >
        <NavMenu />
        <Container fluid>
          <div className="d-flex flex-row">
            <div className="col-3" style={{minHeight:'42vh'}}>
              <DigitalClock />
              <InfoCard 
                stuEnrollCur={stuEnrollCountCur} 
                stuApplyCur={stuApplyCountCur} 
                stuEnrollPrev={stuEnrollCountPrev} 
                stuApplyPrev={stuApplyCountPrev} 
                agents={agentsCount} 
                recruiters={recruitersCount} 
                currYear = {currYear}
                prevYear = {prevYear}
                nextYear = {nextYear}
                remainingDays = {remainingDays}/>
            </div>
            
            {/* <div className="col-1 d-flex align-items-center" style={{width: '3%'}}>
              <button className="btn btn-secondary" style={{transform: 'rotate(90deg)'}} onClick={changeSection('l')}> 
                <FontAwesomeIcon color={"white"} icon={faSortDown} style={{width:'2rem'}}/>
              </button>
            </div> */}

            {/* Section 1 */}
            {isSec1Visible && (
              <div className={`col ${isSec1Visible ? slideVisible : slideExit}`} style={{minHeight:'42rem'}}>
                <div style={{height:'50%'}}> 
                  <Graph data={enrollVsMonthPrev} data1={enrollVsMonth} title={"Student Enrolment " + prevYear + " vs " + currYear } 
                    colText1={`${prevYear}`} colText2={`${currYear}`} graphType={"multiCol"} height="95%" width="95%"/>  
                </div>
                <div style={{height:'50%', display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                  <Graph data={applyVsMonthPrev} data1={applyVsMonth} title={"Student Application " + prevYear + " vs " + currYear} 
                    colText1={`${prevYear}`} colText2={`${currYear}`} graphType={"multiCol"} height="95%" width="95%"/>                
                </div>
              </div>
            )}
            {isSec1Visible && (
              <div className={`col ${isSec1Visible ? slideVisible : slideExit}`} style={{minHeight:'42rem'}}>
                <div style={{height:'50%'}}> 
                  <PerformanceCard enrollmentList={topEnroll} title={`Latest 5 Enrolments`} type="enrollment" height="95%" width="95%"/>
                </div>
                <div style={{height:'50%', display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                  <PerformanceCard enrollmentList={topApplication} title={`Latest 5 Applications`} type="application" height="95%" width="95%"/> 
                </div>
              </div>
            )}


            {/* Section 2 */}
            {isSec2Visible && (
              <div className={`col ${isSec2Visible ? slideVisible : slideExit}`} style={{minHeight:'42rem'}}>
                <div style={{height:'100%'}}> 
                  <Graph data={enrollCourse} title={"Course Enrolment - " + currYear } 
                  title1={"Student Count"} title2={"Courses"} graphType={"barChart"} interval={2} height="100%" width="95%"/>  
                </div>
              </div>
            )}
            {isSec2Visible && (
              <div className={`col ${isSec2Visible ? slideVisible : slideExit}`} style={{minHeight:'40rem'}}>
                <div className='d-flex flex-row justify-content-between' style={{height:'50%', width:"95%"}}> 
                  <Graph data={studentGenders} title={"Enrolment By Gender Groups - " + currYear} graphType={"pie"} height="95%" width="48%" radius="75"/>
                  <Graph data={stuEnrollCountCur} data1={stuApplyCountCur-stuEnrollCountCur} label={"Enrolled"} label1={"Remaining"} 
                    title={"Application to Enrolment Conversion Rate - " + currYear} subtitle={((stuEnrollCountCur*100)/stuApplyCountCur).toFixed(1) + ' %'} graphType={"doughnut"} height="95%" width="48%" radius="75"/>
                </div>
                <div style={{height:'50%', display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                  <Graph data={studentAgeGroups} title={"Enrolment By Age Groups - " + currYear} 
                  title1="Student Count" title2="Age Groups" graphType={"barChart"} interval={5} height="100%" width="95%"/>
                </div>
              </div>
            )}
            

            {/* Section 3 */}
            {isSec3Visible && (
              <div className={`col ${isSec3Visible ? slideVisible : slideExit} d-flex justify-content-center align-items-center`} style={{minHeight:'42rem'}}>
                <Map data1={studentCounts} data2={studentCountries}/>
              </div>
            )}

            <div className="col-1 d-flex align-items-center" style={{width: '3%'}}>
              <button className="btn btn-secondary" style={{transform: 'rotate(-90deg)'}} onClick ={changeSection}> 
                <FontAwesomeIcon color={"white"} icon={faSortDown} style={{width:'2rem'}}/>
              </button>
            </div>


            {/* ======= GRAPH FOR ENROLL BY COUNTRY
            <Graph data={enrollCountry} title={"Student Enrolment by Country " + currYear} graphType={"doughnut"}/> 
            */}

            {/* ========== STUDENT APPLICATION 2023 vs 2024
            <div className="col" style={{height:'80vh !important'}}>
              <div style={{height:'50%'}}>
                <Graph data={applyVsCountry} title={"Student Application by Country " + currYear} graphType={"doughnut"}/>
              </div>
              <div style={{height:'50%', display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                <Graph data={applyVsCountryPrev} title={"Student Application by Country " + prevYear} graphType={"doughnut"}/>
              </div>
            </div> */}
            
          </div>
        </Container>
    </div>
  );
}

export default DashboardHome;