import CanvasJSReact from '@canvasjs/react-charts';
import Card from 'react-bootstrap/Card';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Graph(props){

  let graphType = props.graphType;

  if (props.data){
    let options = {
      theme: "dark2",
      title: {
        text: props.title,
        fontSize: 20
      },
      axisY: {
        includeZero: false,
      },
      axisX: {
        interval: 1
      },
      data: [{
        type: graphType,
        yValueFormatString: "#,### Students",
        dataPoints: props.data
      }]
    } 

    if (graphType === "pie"){
      options = {
        theme: "dark2",
        title: {
          text: props.title,
          fontSize: 20
        },
        data: [{
          type: "pie",
          startAngle: 40,
          radius: props.radius,
          indexLabelFontSize: 14,
          indexLabel: "{label} - #percent%  ", 
          toolTipContent: "<b>{label}:</b> {y} (#percent%)",
          percentFormatString: "#0.0",    
          dataPoints: props.data.map((point, index) => ({
            ...point,
            color: getColor(index)  // Assign color to each column
          }))
        }]
      }
    }

    else if (graphType == "doughnut"){
      options = {
        theme: "dark2",
        title: {
          text: props.title,
          fontSize: 20
        },
        subtitles: [{
          text: props.subtitle,
          verticalAlign: "center",
          dockInsidePlotArea: true,
          fontSize: 30
        }],
        data: [{
          type: "doughnut", 
          radius: props.radius,
          startAngle: 270,
          innerRadius: "80%",
          indexLabelFontSize: 14,
          dataPoints: [
            { y: props.data,  color: "orange", toolTipContent: "<b>Enrolled</b>: {y} students" }, 
            { y: props.data1, color: "white", toolTipContent: "<b>Remaining</b>: {y} students" }
          ]
        }]
      };
    }
    
    else if (graphType === "multiCol"){
      options = {
        theme: "dark2",
        title: {
          text: props.title,
          fontSize: 20
        },	
        toolTip: {
          shared: true
        },
        axisX: {
          interval: 1
        },
        data: [{
          type: "column",
          legendText: props.colText1,
          showInLegend: true, 
          dataPoints:props.data,
          color: "#f07819"
        },
        {
          type: "column",	
          legendText: props.colText2,
          showInLegend: true,
          dataPoints:props.data1,
          color: "#ebe4d6"
        }]
      }
    }

    else if (graphType === "barChart") {
      options = {
        theme: "dark2",
        title: {
          text: props.title,
          fontSize: 20
        },
        toolTip: {
          shared: true
        },
        axisY: {
          title: props.title1,
          interval: props.interval,
          labelFontSize: 14,
          titleFontSize: 16
        },
        axisX: {
          title: props.title2,
          interval: 1,
          labelFormatter: function (e) {
            return e.label;
          },
          labelFontSize: 14,
          titleFontSize: 16
        },
        data: [{
          type: "bar",
          showInLegend: false,
          indexLabelFontWeight: "bold",
          dataPoints: props.data.map(dataPoint => ({
            ...dataPoint,
            indexLabel: String(dataPoint.y), 
            indexLabelPlacement: "outside", 
            indexLabelFontSize: 17
          }))
        }]
      }
    }

    const containerProps = {
        width: "100%",
        height: "100%",
    }
                
    return (
      <Card className="dark" style={{ height: props.height, width: props.width}}>
        <Card.Body>
          <CanvasJSChart options = {options} containerProps = {containerProps} />
        </Card.Body>
      </Card>
    );
  }
}

function getColor(index) {
  const colors = ["#6ca0dc", "#ffb6c1", "#9BBB58", "#24BEAA"]; // Add more colors if needed
  return colors[index % colors.length]; // Cycle through colors
}

export default Graph;


