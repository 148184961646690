import React from "react";
import { ComposableMap, Geographies, Geography, ZoomableGroup, Annotation, Marker } from 'react-simple-maps'
import { scaleSequential } from "d3-scale";
import { interpolateBlues } from "d3-scale-chromatic";
import '../../src/App.css';

// const geoUrl = "https://raw.githubusercontent.com/PublicaMundi/MappingAPI/master/data/geojson/countries.geojson";

const colorScale = scaleSequential(interpolateBlues)
  .domain([0, 100]);

function Map(props) {
  return (
<div style={{ width: '100%', height: '48rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <ComposableMap
    projection="geoMercator"
    projectionConfig={{ scale: 500, center: [110, 15] }}
    style={{ width: '100%', height: '100%' }}
  >
    <ZoomableGroup zoom={1} minZoom={1} maxZoom={8}>
      <Geographies geography={"/world_map_features.json"}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill={colorScale(70)}
              stroke="#ffffff"
              strokeWidth={0.5}
            />
          ))
        }
      </Geographies>

      {/* Markers for student */}
      {props.data1.map((location, index) => (
        <Annotation
          key={`student-${index}`}
          subject={location.coordinates}
          dx={10} 
          dy={-20} 
          connectorProps={{ stroke: "#FF5722", strokeWidth: 1.5 }}
        >
          <text
            textAnchor="middle"
            fontSize={16}
            fontWeight={800}
            fill="#000000"
            stroke="#FFFFFF" 
            strokeWidth={0.5} 
          >
            {location.student_count}
          </text>
        </Annotation>
      ))}

      {/* Markers for country*/}
      {props.data2.map((country, index) => (
        <Marker key={`country-${index}`} coordinates={country.coordinates}>
          <circle r={4} fill="#FF5722" stroke="#FFFFFF" strokeWidth={1} />
          <text
            textAnchor="middle"
            y={15}
            fontSize={14}
            fontWeight={900}
            fill="#FFFFFF"
            stroke="#000000" 
            strokeWidth={0.9}
          >
            {country.country_name}
          </text>
        </Marker>
      ))}
    </ZoomableGroup>
  </ComposableMap>
</div>

  );
}

export default Map;