import '../../src/App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function RecruiterPerformanceMonthlyTable(props){

    let data = props.tableData; 
    let data1 = props.recruitHeads;
    let recruitHeads = [];
    let recruiters = [];

    data1.forEach((element, index) => {
        if (element.recruiter_code != null && element.recruiter_code !== '0013'){
            recruitHeads.push(element.recruiter_code);
        }
    });

    //Sorting recruiters list
    recruitHeads.forEach((recruitHead, i)=>{
        data.forEach((recruiter, index)=>{
            if (recruitHead === (recruiter.recruiter_code) && recruiter.is_team_head === 1){    // recruitment head first
                recruiters.push(recruiter);
            }
        })
        data.forEach((recruiter, index)=>{                                                      // then recruiters under respective recruit head
            if(recruitHead === (recruiter.supervisorCode)){
                recruiters.push(recruiter);
            }
        })
    });
    data.forEach((recruiter, index)=>{                                                          // then recruiters without recruit head
        if(!recruiter.supervisorCode || recruiter.supervisorCode == 1){
            recruiters.push(recruiter);
        }
    })

    let groupOrder = recruitHeads[0];

    let janTotalCount = 0;
    let febTotalCount = 0;
    let marTotalCount = 0;
    let aprTotalCount = 0;
    let mayTotalCount = 0;
    let junTotalCount = 0;
    let julTotalCount = 0;
    let augTotalCount = 0;
    let sepTotalCount = 0;
    let octTotalCount = 0;
    let novTotalCount = 0;
    let decTotalCount = 0;

    let janTotalQuota = 0;
    let febTotalQuota = 0;
    let marTotalQuota = 0;
    let aprTotalQuota = 0;
    let mayTotalQuota = 0;
    let junTotalQuota = 0;
    let julTotalQuota = 0;
    let augTotalQuota = 0;
    let sepTotalQuota = 0;
    let octTotalQuota = 0;
    let novTotalQuota = 0;
    let decTotalQuota = 0;

    let janTotalVs = 0;
    let febTotalVs = 0;
    let marTotalVs = 0;
    let aprTotalVs = 0;
    let mayTotalVs = 0;
    let junTotalVs = 0;
    let julTotalVs = 0;
    let augTotalVs = 0;
    let sepTotalVs = 0;
    let octTotalVs = 0;
    let novTotalVs = 0;
    let decTotalVs = 0;

    let janSubTotalCount = 0;
    let febSubTotalCount = 0;
    let marSubTotalCount = 0;
    let aprSubTotalCount = 0;
    let maySubTotalCount = 0;
    let junSubTotalCount = 0;
    let julSubTotalCount = 0;
    let augSubTotalCount = 0;
    let sepSubTotalCount = 0;
    let octSubTotalCount = 0;
    let novSubTotalCount = 0;
    let decSubTotalCount = 0;

    let janSubTotalQuota = 0;
    let febSubTotalQuota = 0;
    let marSubTotalQuota = 0;
    let aprSubTotalQuota = 0;
    let maySubTotalQuota = 0;
    let junSubTotalQuota = 0;
    let julSubTotalQuota = 0;
    let augSubTotalQuota = 0;
    let sepSubTotalQuota = 0;
    let octSubTotalQuota = 0;
    let novSubTotalQuota = 0;
    let decSubTotalQuota = 0;

    let janSubTotalVs = 0;
    let febSubTotalVs = 0;
    let marSubTotalVs = 0;
    let aprSubTotalVs = 0;
    let maySubTotalVs = 0;
    let junSubTotalVs = 0;
    let julSubTotalVs = 0;
    let augSubTotalVs = 0;
    let sepSubTotalVs = 0;
    let octSubTotalVs = 0;
    let novSubTotalVs = 0;
    let decSubTotalVs = 0;

    function resetSubData (){
        janSubTotalCount = 0;
        febSubTotalCount = 0;
        marSubTotalCount = 0;
        aprSubTotalCount = 0;
        maySubTotalCount = 0;
        junSubTotalCount = 0;
        julSubTotalCount = 0;
        augSubTotalCount = 0;
        sepSubTotalCount = 0;
        octSubTotalCount = 0;
        novSubTotalCount = 0;
        decSubTotalCount = 0;
    
        janSubTotalQuota = 0;
        febSubTotalQuota = 0;
        marSubTotalQuota = 0;
        aprSubTotalQuota = 0;
        maySubTotalQuota = 0;
        junSubTotalQuota = 0;
        julSubTotalQuota = 0;
        augSubTotalQuota = 0;
        sepSubTotalQuota = 0;
        octSubTotalQuota = 0;
        novSubTotalQuota = 0;
        decSubTotalQuota = 0;

        janSubTotalVs = 0;
        febSubTotalVs = 0;
        marSubTotalVs = 0;
        aprSubTotalVs = 0;
        maySubTotalVs = 0;
        junSubTotalVs = 0;
        julSubTotalVs = 0;
        augSubTotalVs = 0;
        sepSubTotalVs = 0;
        octSubTotalVs = 0;
        novSubTotalVs = 0;
        decSubTotalVs = 0;
    }

    return (
        <div className='tableWrap'>
            <table>
                <thead>
                    <tr>     
                        <th className="table-header" rowSpan="3" colSpan="1" style={{width:'5%'}}>Recruiter</th>
                        <th className="table-header" rowSpan="3" colSpan="1" style={{width:'5%'}}>Territory</th>
                        <th className="table-header" rowSpan="1" colSpan="3">Jan {props.year}</th>
                        <th className="table-header" rowSpan="1" colSpan="3">Feb {props.year}</th>
                        <th className="table-header" rowSpan="1" colSpan="3">Mar {props.year}</th>
                        <th className="table-header" rowSpan="1" colSpan="3">Apr {props.year}</th>
                        <th className="table-header" rowSpan="1" colSpan="3">May {props.year}</th>
                        <th className="table-header" rowSpan="1" colSpan="3">Jun {props.year}</th>
                        <th className="table-header" rowSpan="1" colSpan="3">Jul {props.year}</th>
                        <th className="table-header" rowSpan="1" colSpan="3">Aug {props.year}</th>
                        <th className="table-header" rowSpan="1" colSpan="3">Sep {props.year}</th>
                        <th className="table-header" rowSpan="1" colSpan="3">Oct {props.year}</th>
                        <th className="table-header" rowSpan="1" colSpan="3">Nov {props.year}</th>
                        <th className="table-header" rowSpan="1" colSpan="3">Dec {props.year}</th>
                        <th className="table-header" rowSpan="1" colSpan="3" style={{width:'10%'}}>Total {props.year}</th>
                    </tr>
                    <tr>
                        {Array.from({ length: 13 }, (_, index) => (
                            <React.Fragment key={index}>
                                <th className="table-header  small-text">A</th>
                                <th className="table-header  small-text">P</th>
                                <th className="table-header  small-text">Vs</th>
                            </React.Fragment>
                        ))}
                    </tr>
                </thead>

                { <tbody>
                    {recruiters.map((element, index) => {
                        const nextElement = recruiters[index + 1];
                        
                        if (nextElement) {
                            if (nextElement.is_team_head && recruitHeads.includes(nextElement.recruiter_code)){
                                groupOrder = nextElement.recruiter_code;
                            }
                            else{
                                groupOrder = nextElement.supervisorCode;
                            }   
                        }

                        const isLastElement = index === recruiters.length - 1;

                        const janVs = ((element.jan_count === 0 || element.jan_quota === 0) ? 0 : Math.round((element.jan_count/element.jan_quota)*100));
                        const febVs = ((element.feb_count === 0 || element.feb_quota === 0) ? 0 : Math.round((element.feb_count/element.feb_quota)*100));
                        const marVs = ((element.mar_count === 0 || element.mar_quota === 0) ? 0 : Math.round((element.mar_count/element.mar_quota)*100));
                        const aprVs = ((element.apr_count === 0 || element.apr_quota === 0) ? 0 : Math.round((element.apr_count/element.apr_quota)*100));
                        const mayVs = ((element.may_count === 0 || element.may_quota === 0) ? 0 : Math.round((element.may_count/element.may_quota)*100));
                        const junVs = ((element.jun_count === 0 || element.jun_quota === 0) ? 0 : Math.round((element.jun_count/element.jun_quota)*100));
                        const julVs = ((element.jul_count === 0 || element.jul_quota === 0) ? 0 : Math.round((element.jul_count/element.jul_quota)*100));
                        const augVs = ((element.aug_count === 0 || element.aug_quota === 0) ? 0 : Math.round((element.aug_count/element.aug_quota)*100));
                        const sepVs = ((element.sep_count === 0 || element.sep_quota === 0) ? 0 : Math.round((element.sep_count/element.sep_quota)*100));
                        const octVs = ((element.oct_count === 0 || element.oct_quota === 0) ? 0 : Math.round((element.oct_count/element.oct_quota)*100));
                        const novVs = ((element.nov_count === 0 || element.nov_quota === 0) ? 0 : Math.round((element.nov_count/element.nov_quota)*100));
                        const decVs = ((element.dec_count === 0 || element.dec_quota === 0) ? 0 : Math.round((element.dec_count/element.dec_quota)*100));

                        janTotalCount += element.jan_count; 
                        febTotalCount += element.feb_count;
                        marTotalCount += element.mar_count;
                        aprTotalCount += element.apr_count;
                        mayTotalCount += element.may_count;
                        junTotalCount += element.jun_count;
                        julTotalCount += element.jul_count;
                        augTotalCount += element.aug_count;
                        sepTotalCount += element.sep_count;
                        octTotalCount += element.oct_count;
                        novTotalCount += element.nov_count;
                        decTotalCount += element.dec_count;
                        let totalCount = element.jan_count + element.feb_count + element.mar_count + element.apr_count + element.may_count + element.jun_count + 
                                        element.jul_count + element.aug_count + element.sep_count + element.oct_count + element.nov_count + element.dec_count;

                        janTotalQuota += element.jan_quota; 
                        febTotalQuota += element.feb_quota;
                        marTotalQuota += element.mar_quota;
                        aprTotalQuota += element.apr_quota;
                        mayTotalQuota += element.may_quota;
                        junTotalQuota += element.jun_quota;
                        julTotalQuota += element.jul_quota;
                        augTotalQuota += element.aug_quota;
                        sepTotalQuota += element.sep_quota;
                        octTotalQuota += element.oct_quota;
                        novTotalQuota += element.nov_quota;
                        decTotalQuota += element.dec_quota;
                        let totalQuota = element.jan_quota + element.feb_quota + element.mar_quota + element.apr_quota + element.may_quota + element.jun_quota + 
                                        element.aug_quota + element.sep_quota + element.oct_quota + element.nov_quota + element.dec_quota;

                        janTotalVs = ((janTotalCount === 0 || janTotalQuota === 0) ? 0 : Math.round((janTotalCount/janTotalQuota)*100)); 
                        febTotalVs = ((febTotalCount === 0 || febTotalQuota === 0) ? 0 : Math.round((febTotalCount/febTotalQuota)*100));
                        marTotalVs = ((marTotalCount === 0 || marTotalQuota === 0) ? 0 : Math.round((marTotalCount/marTotalQuota)*100));
                        aprTotalVs = ((aprTotalCount === 0 || aprTotalQuota === 0) ? 0 : Math.round((aprTotalCount/aprTotalQuota)*100));
                        mayTotalVs = ((mayTotalCount === 0 || mayTotalQuota === 0) ? 0 : Math.round((mayTotalCount/mayTotalQuota)*100));
                        junTotalVs = ((junTotalCount === 0 || junTotalQuota === 0) ? 0 : Math.round((junTotalCount/junTotalQuota)*100));
                        julTotalVs = ((julTotalCount === 0 || julTotalQuota === 0) ? 0 : Math.round((julTotalCount/julTotalQuota)*100));
                        augTotalVs = ((augTotalCount === 0 || augTotalQuota === 0) ? 0 : Math.round((augTotalCount/augTotalQuota)*100));
                        sepTotalVs = ((sepTotalCount === 0 || sepTotalQuota === 0) ? 0 : Math.round((sepTotalCount/sepTotalQuota)*100));
                        octTotalVs = ((octTotalCount === 0 || octTotalQuota === 0) ? 0 : Math.round((octTotalCount/octTotalQuota)*100));
                        novTotalVs = ((novTotalCount === 0 || novTotalQuota === 0) ? 0 : Math.round((novTotalCount/novTotalQuota)*100));
                        decTotalVs = ((decTotalCount === 0 || decTotalQuota === 0) ? 0 : Math.round((decTotalCount/decTotalQuota)*100));


                        let totalVs = ((totalCount === 0 || totalQuota === 0) ? 0 : Math.round((totalCount/totalQuota)*100).toFixed(1)); 

                        janSubTotalCount += element.jan_count;
                        febSubTotalCount += element.feb_count;
                        marSubTotalCount += element.mar_count;
                        aprSubTotalCount += element.apr_count;
                        maySubTotalCount += element.may_count;
                        junSubTotalCount += element.jun_count;
                        julSubTotalCount += element.jul_count;
                        augSubTotalCount += element.aug_count;
                        sepSubTotalCount += element.sep_count;
                        octSubTotalCount += element.oct_count;
                        novSubTotalCount += element.nov_count;
                        decSubTotalCount += element.dec_count;
                    
                        janSubTotalQuota += element.jan_quota;
                        febSubTotalQuota += element.feb_quota;
                        marSubTotalQuota += element.mar_quota;
                        aprSubTotalQuota += element.apr_quota;
                        maySubTotalQuota += element.may_quota;
                        junSubTotalQuota += element.jun_quota;
                        julSubTotalQuota += element.jul_quota;
                        augSubTotalQuota += element.aug_quota;
                        sepSubTotalQuota += element.sep_quota;
                        octSubTotalQuota += element.oct_quota;
                        novSubTotalQuota += element.nov_quota;
                        decSubTotalQuota += element.dec_quota;
                
                        janSubTotalVs = ((janSubTotalCount === 0 || janSubTotalQuota === 0) ? 0 : Math.round((janSubTotalCount/janSubTotalQuota)*100));
                        febSubTotalVs = ((febSubTotalCount === 0 || febSubTotalQuota === 0) ? 0 : Math.round((febSubTotalCount/febSubTotalQuota)*100));
                        marSubTotalVs = ((marSubTotalCount === 0 || marSubTotalQuota === 0) ? 0 : Math.round((marSubTotalCount/marSubTotalQuota)*100));
                        aprSubTotalVs = ((aprSubTotalCount === 0 || aprSubTotalQuota === 0) ? 0 : Math.round((aprSubTotalCount/aprSubTotalQuota)*100));
                        maySubTotalVs = ((maySubTotalCount === 0 || maySubTotalQuota === 0) ? 0 : Math.round((maySubTotalCount/maySubTotalQuota)*100));
                        junSubTotalVs = ((junSubTotalCount === 0 || junSubTotalQuota === 0) ? 0 : Math.round((junSubTotalCount/junSubTotalQuota)*100));
                        julSubTotalVs = ((julSubTotalCount === 0 || julSubTotalQuota === 0) ? 0 : Math.round((julSubTotalCount/julSubTotalQuota)*100));
                        augSubTotalVs = ((augSubTotalCount === 0 || augSubTotalQuota === 0) ? 0 : Math.round((augSubTotalCount/augSubTotalQuota)*100));
                        sepSubTotalVs = ((sepSubTotalCount === 0 || sepSubTotalQuota === 0) ? 0 : Math.round((sepSubTotalCount/sepSubTotalQuota)*100));
                        octSubTotalVs = ((octSubTotalCount === 0 || octSubTotalQuota === 0) ? 0 : Math.round((octSubTotalCount/octSubTotalQuota)*100));
                        novSubTotalVs = ((novSubTotalCount === 0 || novSubTotalQuota === 0) ? 0 : Math.round((novSubTotalCount/novSubTotalQuota)*100));
                        decSubTotalVs = ((decSubTotalCount === 0 || decSubTotalQuota === 0) ? 0 : Math.round((decSubTotalCount/decSubTotalQuota)*100));

                        // to add a sub total row when recruitment team is changed
                        if ((groupOrder !== element.supervisorCode && groupOrder !== element.recruiter_code) || isLastElement){
                            
                            return (
                                <>
                                    <tr key={index}>
                                        <td className="table-data">{element.recruiter_name}</td>
                                        <td className="table-data">{element.country_name}</td>              
        
                                        <td className="table-data">{element.jan_count}</td>
                                        <td className="table-data">{element.jan_quota}</td>
                                        <td className="table-data">{janVs}%</td>
        
                                        <td className="table-data">{element.feb_count}</td>
                                        <td className="table-data">{element.feb_quota}</td>
                                        <td className="table-data">{febVs}%</td>
        
                                        <td className="table-data">{element.mar_count}</td>
                                        <td className="table-data">{element.mar_quota}</td>
                                        <td className="table-data">{marVs}%</td>
        
                                        <td className="table-data">{element.apr_count}</td>
                                        <td className="table-data">{element.apr_quota}</td>
                                        <td className="table-data">{aprVs}%</td>
        
                                        <td className="table-data">{element.may_count}</td>
                                        <td className="table-data">{element.may_quota}</td>
                                        <td className="table-data">{mayVs}%</td>
        
                                        <td className="table-data">{element.jun_count}</td>
                                        <td className="table-data">{element.jun_quota}</td>
                                        <td className="table-data">{junVs}%</td>
        
                                        <td className="table-data">{element.jul_count}</td>
                                        <td className="table-data">{element.jul_quota}</td>
                                        <td className="table-data">{julVs}%</td>
        
                                        <td className="table-data">{element.aug_count}</td>
                                        <td className="table-data">{element.aug_quota}</td>
                                        <td className="table-data">{augVs}%</td>
        
                                        <td className="table-data">{element.sep_count}</td>
                                        <td className="table-data">{element.sep_quota}</td>
                                        <td className="table-data">{sepVs}%</td>
        
                                        <td className="table-data">{element.oct_count}</td>
                                        <td className="table-data">{element.oct_quota}</td>
                                        <td className="table-data">{octVs}%</td>
        
                                        <td className="table-data">{element.nov_count}</td>
                                        <td className="table-data">{element.nov_quota}</td>
                                        <td className="table-data">{novVs}%</td>
        
                                        <td className="table-data">{element.dec_count}</td>
                                        <td className="table-data">{element.dec_quota}</td>
                                        <td className="table-data">{decVs}%</td>

                                        <td className="table-data">{totalCount}</td>
                                        <td className="table-data">{totalQuota}</td>
                                        <td className="table-data">{totalVs}%</td>
                                    </tr>
                                    <tr className="sub-group">
                                        <td className="table-data4" colSpan="2">Sub-Total</td>              
        
                                        <td className="table-data4">{janSubTotalCount}</td>
                                        <td className="table-data4">{janSubTotalQuota}</td>
                                        <td className="table-data4">{janSubTotalVs}%</td>
        
                                        <td className="table-data4">{febSubTotalCount}</td>
                                        <td className="table-data4">{febSubTotalQuota}</td>
                                        <td className="table-data4">{febSubTotalVs}%</td>
        
                                        <td className="table-data4">{marSubTotalCount}</td>
                                        <td className="table-data4">{marSubTotalQuota}</td>
                                        <td className="table-data4">{marSubTotalVs}%</td>
        
                                        <td className="table-data4">{aprSubTotalCount}</td>
                                        <td className="table-data4">{aprSubTotalQuota}</td>
                                        <td className="table-data4">{aprSubTotalVs}%</td>
        
                                        <td className="table-data4">{maySubTotalCount}</td>
                                        <td className="table-data4">{maySubTotalQuota}</td>
                                        <td className="table-data4">{maySubTotalVs}%</td>
        
                                        <td className="table-data4">{junSubTotalCount}</td>
                                        <td className="table-data4">{junSubTotalQuota}</td>
                                        <td className="table-data4">{junSubTotalVs}%</td>

                                        <td className="table-data4">{julSubTotalCount}</td>
                                        <td className="table-data4">{julSubTotalQuota}</td>
                                        <td className="table-data4">{julSubTotalVs}%</td>

                                        <td className="table-data4">{augSubTotalCount}</td>
                                        <td className="table-data4">{augSubTotalQuota}</td>
                                        <td className="table-data4">{augSubTotalVs}%</td>

                                        <td className="table-data4">{sepSubTotalCount}</td>
                                        <td className="table-data4">{sepSubTotalQuota}</td>
                                        <td className="table-data4">{sepSubTotalVs}%</td>

                                        <td className="table-data4">{octSubTotalCount}</td>
                                        <td className="table-data4">{octSubTotalQuota}</td>
                                        <td className="table-data4">{octSubTotalVs}%</td>

                                        <td className="table-data4">{novSubTotalCount}</td>
                                        <td className="table-data4">{novSubTotalQuota}</td>
                                        <td className="table-data4">{novSubTotalVs}%</td>

                                        <td className="table-data4">{decSubTotalCount}</td>
                                        <td className="table-data4">{decSubTotalQuota}</td>
                                        <td className="table-data4">{decSubTotalVs}%</td>

                                        <td className="table-data4"></td>
                                        <td className="table-data4"></td>
                                        <td className="table-data4"></td>
                                    </tr>
                                    {resetSubData()}
                                </>
                                
                            )
                        }
                        else{
                            return (
                                <tr key={index}>
                                    <td className="table-data">{element.recruiter_name}</td>
                                    <td className="table-data">{element.country_name}</td>              
    
                                    <td className="table-data">{element.jan_count}</td>
                                    <td className="table-data">{element.jan_quota}</td>
                                    <td className="table-data">{janVs}%</td>
    
                                    <td className="table-data">{element.feb_count}</td>
                                    <td className="table-data">{element.feb_quota}</td>
                                    <td className="table-data">{febVs}%</td>
    
                                    <td className="table-data">{element.mar_count}</td>
                                    <td className="table-data">{element.mar_quota}</td>
                                    <td className="table-data">{marVs}%</td>
    
                                    <td className="table-data">{element.apr_count}</td>
                                    <td className="table-data">{element.apr_quota}</td>
                                    <td className="table-data">{aprVs}%</td>
    
                                    <td className="table-data">{element.may_count}</td>
                                    <td className="table-data">{element.may_quota}</td>
                                    <td className="table-data">{mayVs}%</td>
    
                                    <td className="table-data">{element.jun_count}</td>
                                    <td className="table-data">{element.jun_quota}</td>
                                    <td className="table-data">{junVs}%</td>
    
                                    <td className="table-data">{element.jul_count}</td>
                                    <td className="table-data">{element.jul_quota}</td>
                                    <td className="table-data">{julVs}%</td>
    
                                    <td className="table-data">{element.aug_count}</td>
                                    <td className="table-data">{element.aug_quota}</td>
                                    <td className="table-data">{augVs}%</td>
    
                                    <td className="table-data">{element.sep_count}</td>
                                    <td className="table-data">{element.sep_quota}</td>
                                    <td className="table-data">{sepVs}%</td>
    
                                    <td className="table-data">{element.oct_count}</td>
                                    <td className="table-data">{element.oct_quota}</td>
                                    <td className="table-data">{octVs}%</td>
    
                                    <td className="table-data">{element.nov_count}</td>
                                    <td className="table-data">{element.nov_quota}</td>
                                    <td className="table-data">{novVs}%</td>
    
                                    <td className="table-data">{element.dec_count}</td>
                                    <td className="table-data">{element.dec_quota}</td>
                                    <td className="table-data">{decVs}%</td>

                                    <td className="table-data">{totalCount}</td>
                                    <td className="table-data">{totalQuota}</td>
                                    <td className="table-data">{totalVs}%</td>
                                </tr>
                            )
                        }

                    })}
                    <tr>
                        <td className="table-data2">Grand Total</td>
                        <td className="table-data2">All Region</td>

                        <td className="table-data2">{janTotalCount}</td>
                        <td className="table-data2">{janTotalQuota}</td>
                        <td className="table-data2">{janTotalVs}%</td>

                        <td className="table-data2">{febTotalCount}</td>
                        <td className="table-data2">{febTotalQuota}</td>
                        <td className="table-data2">{febTotalVs}%</td>

                        <td className="table-data2">{marTotalCount}</td>
                        <td className="table-data2">{marTotalQuota}</td>
                        <td className="table-data2">{marTotalVs}%</td>

                        <td className="table-data2">{aprTotalCount}</td>
                        <td className="table-data2">{aprTotalQuota}</td>
                        <td className="table-data2">{aprTotalVs}%</td>

                        <td className="table-data2">{mayTotalCount}</td>
                        <td className="table-data2">{mayTotalQuota}</td>
                        <td className="table-data2">{mayTotalVs}%</td>

                        <td className="table-data2">{junTotalCount}</td>
                        <td className="table-data2">{junTotalQuota}</td>
                        <td className="table-data2">{junTotalVs}%</td>

                        <td className="table-data2">{julTotalCount}</td>
                        <td className="table-data2">{julTotalQuota}</td>
                        <td className="table-data2">{julTotalVs}%</td>

                        <td className="table-data2">{augTotalCount}</td>
                        <td className="table-data2">{augTotalQuota}</td>
                        <td className="table-data2">{augTotalVs}%</td>

                        <td className="table-data2">{sepTotalCount}</td>
                        <td className="table-data2">{sepTotalQuota}</td>
                        <td className="table-data2">{sepTotalVs}%</td>

                        <td className="table-data2">{octTotalCount}</td>
                        <td className="table-data2">{octTotalQuota}</td>
                        <td className="table-data2">{octTotalVs}%</td>

                        <td className="table-data2">{novTotalCount}</td>
                        <td className="table-data2">{novTotalQuota}</td>
                        <td className="table-data2">{novTotalVs}%</td>

                        <td className="table-data2">{decTotalCount}</td>
                        <td className="table-data2">{decTotalQuota}</td>
                        <td className="table-data2">{decTotalVs}%</td>

                        <td className="table-data2"></td>
                        <td className="table-data2"></td>
                        <td className="table-data2"></td>

                    </tr>
                </tbody> }
            </table>
        </div>
    )
}
export default RecruiterPerformanceMonthlyTable;